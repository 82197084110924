import * as React from "react";
import { useConnect } from "@hooks/auth";
import type { Connector } from "wagmi";
import { withProviders } from "@providers/hoc";
import Button from "@components/Button/Button";
import { NavBarStateEnums, useNavStore } from "../store";
import { notify, toast } from "@utils/notifications";

function WalletOption({
  connector,
  onClick,
}: {
  connector: Connector;
  onClick: () => void;
}) {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  return (
    <Button
      variant="outlined"
      size="xl"
      disabled={!ready}
      onClick={onClick}
      className="text-white space-x-4 w-full"
    >
      {connector.icon && (
        <div>
          <img alt={connector.name} src={connector.icon} className="h-8" />
        </div>
      )}
      <div>{connector.name}</div>
    </Button>
  );
}

export function WalletOptionsContainer({ className }: { className?: string }) {
  const { connectors, connectAsync } = useConnect();
  const [nav, setNav] = useNavStore();

  const allowedConnectorIds = [
    "MetaMask",
    "io.zerion.wallet",
    "io.rabby",
    "io.metamask",
  ];

  const installedConnectors = connectors.filter((connector) => connector.icon);

  const defaultConnectors = connectors.filter((connector) => !connector.icon);

  const defaultConnectorsId = defaultConnectors.map((c) => c.name);

  const allowedInstalledConnectors = installedConnectors.filter(
    (c) =>
      defaultConnectorsId.includes(c.name) || allowedConnectorIds.includes(c.id)
  );

  if (!installedConnectors.length) {
    return (
      <div className={className}>
        {defaultConnectors.map((connector) => (
          <WalletOption
            key={connector.uid}
            connector={connector}
            onClick={async () => {
              await notify({
                icon: "info",
                title: "Install Wallet",
                text: `Please install the ${connector.name} extension.`,
              });
              await connectAsync(
                { connector },
                {
                  onSuccess: () => {
                    setNav(NavBarStateEnums.CLOSED);
                    toast({
                      icon: "info",
                      title: "Welcome to Enmei!",
                      text: `You are connected via ${connector.name}.`,
                    });
                  },
                }
              );
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={className}>
      {allowedInstalledConnectors.map((connector) => (
        <WalletOption
          key={connector.uid}
          connector={connector}
          onClick={async () => {
            await toast({
              icon: "info",
              title: "Continue in Wallet.",
              text: `Please continue in your ${connector.name} extension.`,
            });
            await connectAsync(
              { connector },
              {
                onSuccess: () => {
                  setNav(NavBarStateEnums.CLOSED);
                  toast({
                    icon: "info",
                    title: "Welcome to Enmei!",
                    text: `You are connected via ${connector.name}.`,
                  });
                },
              }
            );
          }}
        />
      ))}
    </div>
  );
}

export const WalletOptions = withProviders(WalletOptionsContainer);
